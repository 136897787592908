import React from 'react';
import cn from 'classnames';
import { FacebookPagesSetupQuery_bot_status_page_info_owner as FacebookPageOwner } from '@utils/FacebookPages/@types/FacebookPagesSetupQuery';
import { AvatarWithPlatform } from '@ui/Avatar';
import { Spacer } from '@ui/Spacer';
import { FontSize, FontSizeKey, FontWeightKey } from '@ui/Type';
import { PageArchivingStatus, Platform } from '@globals';
import * as css from './FacebookPageTitle.css';
import {
  PlatformConnectedHeaderWrapper,
  PlatformLink,
} from '../../pages/GrowPage/PlatformConnection';
import { FacebookPageArchivedTooltip } from './FacebookPageArchivedTooltip';
import { FacebookPagePublishedBy } from './FacebookPagePublishedBy';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface FacebookPage {
  id: string;
  title: string;
  picture: string | null;
  archiving_status?: PageArchivingStatus | null;
}

interface FacebookPageTitleProps
  extends React.HTMLProps<HTMLDivElement>,
    TestLocator {
  page: FacebookPage;
  avatarSize: number;
  titleWidthNarrow?: boolean;
  botInfo?: React.ReactNode;
  titleSize?: FontSizeKey;
  titleWeight?: FontWeightKey;
  otherAdminConnected?: FacebookPageOwner | null;
  onClick?: () => void;
}

export const FacebookPageTitle: React.FC<FacebookPageTitleProps> = ({
  page: { id, title, picture, archiving_status: archivingStatus },
  titleWidthNarrow,
  avatarSize,
  botInfo,
  titleSize = FontSize['18px'],
  titleWeight,
  otherAdminConnected,
  onClick,
  ...props
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const isPageArchived = archivingStatus === PageArchivingStatus.ARCHIVED;

  return (
    <PlatformConnectedHeaderWrapper
      reversed={botInfo ? false : isSmallScreenSize}
      className={cn(css.box)}
      data-testid={props['data-testid']}
    >
      <AvatarWithPlatform
        className={css.avatar}
        src={picture || ''}
        size={avatarSize}
        platform={Platform.facebook}
      />
      <Spacer horizontalFactor={3} />
      <div className={css.pageInfoContainer}>
        <PlatformLink
          href={`https://www.facebook.com/${id}`}
          className={css.pageLink}
          onClick={onClick}
          size={titleSize}
          weight={titleWeight}
        >
          {title}
        </PlatformLink>
        {botInfo && <div className={css.botInfo}>{botInfo}</div>}
        {otherAdminConnected && (
          <FacebookPagePublishedBy {...otherAdminConnected} />
        )}
        {isPageArchived && <FacebookPageArchivedTooltip />}
      </div>
    </PlatformConnectedHeaderWrapper>
  );
};
