import React, { useState } from 'react';
import { Button } from '@ui/Button';
import { openMessengerPage } from '@components/FBPlugins/FBPluginsUtils';
import timer from '@utils/timer';
import { SendToMessengerPluginButton } from '@components/FBPlugins/SendToMessengerPluginButton';
import { useAdminData } from '@utils/Data/Admin';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import useVisibilityChange from 'use-visibility-change';

const SEND_TO_MESSENGER_REF = 'admin_user_';
const MIN_TIME_TO_CHATFUEL_BOT_DIALOG = 5000;
const FB_OPT_IN_LAG_TIMEOUT = 1000;

export const LinkFBUserToAdminButton: React.FC = () => {
  const { pageId, loading: pageLoading } = usePageConnected();
  const { adminData, adminDataLoading, adminDataRefetch } = useAdminData();
  const [isLeaveToMessenger, setIsLeaveToMessenger] = useState<boolean>(false);
  const [fbOptInProgress, setFbOptInProgress] = useState<boolean>(false);
  const { t } = useSafeTranslation();
  useVisibilityChange({
    onShow: ({ lastSeenDate }) => {
      if (!isLeaveToMessenger) {
        return;
      }
      setIsLeaveToMessenger(false);
      const creatingPageTime = Date.now() - lastSeenDate;
      if (creatingPageTime > MIN_TIME_TO_CHATFUEL_BOT_DIALOG) {
        adminDataRefetch();
      }
    },
  });
  const safePageId = pageId || window.CHATFUEL_CONFIG.CF_BOT_TEST_PAGE_ID!;
  const dataRef = `${SEND_TO_MESSENGER_REF}${adminData?.me.id}`;
  const loading = pageLoading || adminDataLoading || fbOptInProgress;

  return (
    <SendToMessengerPluginButton
      render={() => (
        <Button
          intent="primary"
          loading={loading}
          onClick={() => {
            setIsLeaveToMessenger(true);
            openMessengerPage({ pageId: safePageId, ref: dataRef });
          }}
        >
          {t('pages.LiveChat.ManageSubscriptions.allowMessaging')}
        </Button>
      )}
      dataRef={dataRef}
      pageId={safePageId}
      appId={window.CHATFUEL_CONFIG.APP_ID!}
      onOptIn={async () => {
        await timer(FB_OPT_IN_LAG_TIMEOUT);
        setFbOptInProgress(false);
        adminDataRefetch();
      }}
      onClickFB={() => {
        setFbOptInProgress(true);
      }}
    />
  );
};
