import { log } from 'cf-common/src/logger';

export const callFacebookSDK = (callback: (fb: any) => void) => {
  if (!window.FB?.XFBML) {
    log.warn({
      msg: 'FB SDK not found',
    });
    return false;
  }
  try {
    callback(window.FB);
    return true;
  } catch (error) {
    log.warn({ error, msg: 'FB SDK error' });
  }
  return false;
};
