import { Modal } from '@services/index';
import { DefaultDialog } from '@ui/Dialog';
import { Gallery } from '@ui/Gallery/Gallery';
import { sendEvent } from '@utils/Analytics';
import { useAdminFeatures } from '@utils/Data/Admin';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import React, { useLayoutEffect, useState } from 'react';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import {
  ACTIVE_FEATURE_PROMO_GALLERIES,
  DEFAULT_DIALOG_TITLE,
  FEATURE_PROMO_GALLERIES_CONFIG,
  FeaturePromoGalleriesKeys,
} from './consts';
import { filterAvailiableFeatures } from './helpers';
import { FeaturePromoSlideConfig } from './types';

export const FeaturesPromoGallery: React.FC = () => {
  const [
    seenFeaturePromoGalleries,
    setSeenFeaturePromoGalleries,
    { loading: seenFeaturePromoGalleriesLoading },
  ] = useServerStorage<FeaturePromoGalleriesKeys[]>(
    ServerStorageItemKeys.seenFeaturePromoGalleries,
  );

  const [shown, setShown] = useState(false);
  const { adminFeatures } = useAdminFeatures();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useLayoutEffect(() => {
    if (isWhiteLabelDomain()) {
      return;
    }
    if (shown) {
      return;
    }
    if (adminFeatures && !seenFeaturePromoGalleriesLoading) {
      const filteredFeatures = filterAvailiableFeatures(
        ACTIVE_FEATURE_PROMO_GALLERIES,
        adminFeatures,
        seenFeaturePromoGalleries ?? [],
      );
      if (!filteredFeatures?.length) {
        return;
      }
      sendEvent({
        category: 'feature promo modal',
        action: 'show modal',
        propertyBag: {
          features: filteredFeatures,
        },
      });
      const setFeaturesAsSeen = () => {
        setSeenFeaturePromoGalleries([
          ...(seenFeaturePromoGalleries ?? []),
          ...filteredFeatures,
        ]);
      };
      Modal.show(({ close, reject, resolve }) => {
        setShown(true);
        const galleryConfig = filteredFeatures.reduce(
          (acc, item) => [
            ...acc,
            ...(FEATURE_PROMO_GALLERIES_CONFIG?.[item]?.map((galleryItem) => ({
              ...galleryItem,
              footer: galleryItem.footer({
                close,
                reject,
                resolve,
              }),
            })) ?? []),
          ],
          [] as FeaturePromoSlideConfig[],
        );

        return (
          <DefaultDialog
            header={
              galleryConfig[currentSlideIndex].dialogTitle ??
              DEFAULT_DIALOG_TITLE
            }
            onDismiss={close}
          >
            <Gallery
              items={galleryConfig}
              height={332}
              onChangeIndex={setCurrentSlideIndex}
            />
          </DefaultDialog>
        );
      })?.onClose(() => {
        sendEvent({
          category: 'feature promo modal',
          action: 'outside click',
          propertyBag: {
            slideIndex: currentSlideIndex,
          },
        });
        setFeaturesAsSeen();
      });
    }
  }, [
    seenFeaturePromoGalleries,
    adminFeatures,
    setSeenFeaturePromoGalleries,
    seenFeaturePromoGalleriesLoading,
    currentSlideIndex,
    shown,
  ]);

  return null;
};
