import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useCurrentBotId } from '@utils/Routing';

import { ENTRY_POINTS_SELECTOR_QUERY } from './queries';
import {
  EntryPointsSelectorQuery,
  EntryPointsSelectorQuery_bot_flow_groups,
  EntryPointsSelectorQuery_bot_flows,
  EntryPointsSelectorQueryVariables,
} from './@types/EntryPointsSelectorQuery';
import {
  HIDDEN_DEPRECATED_ENTRY_POINTS,
  PluginType,
} from '../Plugins/common/PluginTypes';

const getEntryPointsGroupedByFlow = (
  flows: EntryPointsSelectorQuery_bot_flows[],
  flowsGroups: EntryPointsSelectorQuery_bot_flow_groups[],
): Array<{
  id: string;
  title: string;
  items: Array<{ id: string; title: string; flowId: string }>;
}> => {
  const flowIds = flowsGroups.flatMap((flowGroup) => flowGroup.flow_ids);
  return flows
    .map((flow) => ({
      id: flow.id,
      title: flow.title,
      items: (flow.entry_points ?? [])
        .filter(
          ({ enabled, entry_point_type: type }) =>
            !enabled &&
            type &&
            !HIDDEN_DEPRECATED_ENTRY_POINTS.includes(type as PluginType),
        )
        .map((entryPoint) => ({
          ...entryPoint,
          flowId: flow.id,
        })),
    }))
    .filter((flow) => flow.items.length && flowIds.includes(flow.id));
};

export const useEntryPointSelectorData = (flowId?: string) => {
  const botId = useCurrentBotId() ?? '';

  const { data, loading: isDataLoading } = useQuery<
    EntryPointsSelectorQuery,
    EntryPointsSelectorQueryVariables
  >(ENTRY_POINTS_SELECTOR_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const flowEntryPoints = useMemo(
    () =>
      getEntryPointsGroupedByFlow(
        data?.bot?.flows ?? [],
        data?.bot.flow_groups ?? [],
      ).filter(({ id }) => !flowId || id === flowId),
    [data, flowId],
  );

  return {
    flowEntryPoints,
    isDataLoading,
  };
};
