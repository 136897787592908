import { useEffect, useState } from 'react';
import { getFbLoginStatus, GetFbLoginStatusResponse } from './FbLoginStatus';

export const useFbLoginStatus = (force?: boolean) => {
  const [fbLoginStatus, setFbLoginStatus] = useState<
    GetFbLoginStatusResponse | undefined
  >();

  useEffect(() => {
    getFbLoginStatus(force).then((response) => {
      setFbLoginStatus(response);
    });
  }, [force]);

  return fbLoginStatus;
};
