import Maybe from 'graphql/tsutils/Maybe';
import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import { callFacebookSDK } from './callFacebookSDK';

export const parseFbPluginsTemplates = (el?: Element) => {
  setTimeout(() => {
    callFacebookSDK((FB) => {
      if (el) {
        FB.XFBML.parse(el);
      }
    });
  }, 100); // for call after more one tic
};

export const isFbSdkReadyAsync = new Promise((resolve) => {
  let attemptsLimit = 100;
  const testFBInited = () => {
    const { FB } = window;
    if (FB) {
      setTimeout(() => resolve(true), 100); // wait init FB sdk after loading
    } else {
      attemptsLimit -= 1;

      if (attemptsLimit) {
        setTimeout(testFBInited, 100);
      }
    }
  };
  testFBInited();
});

interface OpenMessengerPageArgs {
  pageId: string;
  ref?: Maybe<string>;
  anonymous?: boolean;
}

export const openMessengerPage = ({
  pageId,
  ref,
  anonymous,
}: OpenMessengerPageArgs) => {
  if (!anonymous) {
    serverStorageItemSet(ServerStorageItemKeys.isFlowTested, true);
  }
  window.open(
    `https://m.me/${pageId}${ref ? `?ref=${ref}` : ''}`,
    '_messenger',
  );
};

export const encodeDataRef = (dataRef: string) => `b64:${btoa(dataRef)}`;
