import React from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { EntryPointItem } from '../../entryPoints';
import * as css from './EntryPointModalItem.css';
import { CenteredLoader } from '@ui/Loader';

interface EntryPointModalItemProps {
  item: EntryPointItem;
  showLoader: boolean;
  onClick: () => void;
}

export const EntryPointModalItem: React.FC<EntryPointModalItemProps> = ({
  item,
  showLoader,
  onClick,
}) => {
  const { title, pic, description } = item;

  return (
    <figure className={css.figure}>
      {showLoader && (
        <div className={css.loaderBox}>
          <CenteredLoader className={css.loader} />
        </div>
      )}
      <ButtonUnstyled onClick={onClick} data-testid="entry_point_modal_item">
        <img src={pic} alt="" className={css.pic} />
        <Spacer factor={3} />
        <figcaption>
          <Type size="15px" weight="semibold">
            {title}
          </Type>
          <Spacer factor={2} />
          <Type size="15px">{description}</Type>
        </figcaption>
      </ButtonUnstyled>
    </figure>
  );
};
