import { AdminFeaturesQuery_me_features } from '@utils/Data/Admin/@types/AdminFeaturesQuery';
import { ITEMS as INSTAGRAM_AUTOMATION_ITEMS } from './InstagramAutomation/consts';
import { ITEMS as INSTAGRAM_TEST_ITEMS } from './InstagramTest/consts';
import { ITEMS as INSTAGRAM_INBOUND_LINKS_ITEMS } from './InstagramInboundLinks/constants';
import { FeaturePromoItem } from './types';

export type FeaturePromoGalleriesKeys = keyof AdminFeaturesQuery_me_features;

export const DEFAULT_DIALOG_TITLE = 'New!';

/**
 * List of features, that should be forced shown in promo gallery
 */
export const ACTIVE_FEATURE_PROMO_GALLERIES: FeaturePromoGalleriesKeys[] = [
  // 'instagram_1k_promo_gallery',
  // 'instagram_test_promo_gallery',
  'instagram_inbound_links_promo_gallery',
];

export const FEATURE_PROMO_GALLERIES_CONFIG: Partial<Record<
  FeaturePromoGalleriesKeys,
  FeaturePromoItem[]
>> = {
  instagram_1k_promo_gallery: INSTAGRAM_AUTOMATION_ITEMS,
  instagram_test_promo_gallery: INSTAGRAM_TEST_ITEMS,
  instagram_inbound_links_promo_gallery: INSTAGRAM_INBOUND_LINKS_ITEMS,
};
