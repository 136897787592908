import i18n from 'i18next';
import memoize from 'lodash-es/memoize';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { BlockShortcutId } from '@components/FlowBuilder/views/helpers/blocksShortcuts';
import { Category } from '@components/ItemsModal';
import { Platform } from '@globals';
import instagramDirectEntryPointSrc from './images/instagram_direct_entry_point.png';
import instagramStoryMentionEntryPointSrc from './images/instagram_story_mention_entry_point.png';
import instagramStoryReplyEntryPointSrc from './images/instagram_story_reply_entry_point.png';
import instagramCommentsAutoreplyEntryPointSrc from './images/instagram_comments_autoreply_entry_point.png';
import instagramAdsManagerCtmEntryPointSrc from './images/instagram_ads_manager_ctm_entry_point.png';
import instagramBotLinkEntryPointSrc from './images/instagram_bot_link_entry_point.png';
import refLinkEntryPointSrc from './images/ref_link_entry_point.png';
// import customerChatEntryPointSrc from './images/customer_chat_entry_point.png';
import sendToMessengerEntryPointSrc from './images/send_to_messenger_entry_point.png';
import adsManagerCtmEntryPointSrc from './images/ads_manager_ctm_entry_point.png';
import adsManagerCtmJsonEntryPointSrc from './images/ads_manager_ctm_json_entry_point.png';
import commentsAutoreplyEntryPointSrc from './images/comments_autoreply_entry_point.png';
// import adCommentsAutoreplyEntryPointSrc from './images/ad_comments_autoreply_entry_point.png';
import fbPageEntryPointSrc from './images/fb_page_entry_point.png';
import facebookShopsEntryPointSrc from './images/facebook_shops_entry_point.png';
import persistentMenuEntryPointSrc from './images/persistent_menu_entry_point.png';
import instagramPersistentMenuEntryPointSrc from './images/instagram_persistent_menu_entry_point.png';
// import adsManagerSmEntryPointSrc from './images/ads_manager_sm_entry_point.png';
import whatsappDirectEntryPointSrc from './images/whatsapp_direct_entry_point.png';
import whatsappBotLinkEntryPointSrc from './images/whatsapp_bot_link_entry_point.png';
import whatsappWidgetEntryPointSrc from './images/whatsapp_widget_entry_point.png';
import whatsappPopupEntryPointSrc from './images/whatsapp_popup_entry_point.png';

export type EntryPointItem<T extends Category = Category> = {
  id: PluginType | BlockShortcutId;
  category: T;
  title: string;
  description: string;
  pic: string;
  platform: Platform;
};

type FacebookEntryPoints = Array<EntryPointItem<Category.facebook>>;
type InstagramEntryPoints = Array<EntryPointItem<Category.instagram>>;
type WebsiteEntryPoints = Array<EntryPointItem<Category.website>>;
type WhatsappEntryPoints = Array<EntryPointItem<Category.whatsapp>>;

export interface EntryPointsItemsParams {
  isWhatsappEnabled: boolean;
  isAdCommentsAutoreplyEnabled: boolean;
}

export const getEntryPointsItems = memoize(
  (params: EntryPointsItemsParams): EntryPointItem[] => {
    const instagramEntryPoints: InstagramEntryPoints = [
      {
        id: PluginType.instagram_bot_link_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramBotLink.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramBotLink.description',
        ),
        pic: instagramBotLinkEntryPointSrc,
      },
      {
        id: PluginType.instagram_direct_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramDirect.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramDirect.description',
        ),
        pic: instagramDirectEntryPointSrc,
      },
      {
        id: PluginType.instagram_story_mention_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramStoryMention.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramStoryMention.description',
        ),
        pic: instagramStoryMentionEntryPointSrc,
      },
      {
        id: PluginType.instagram_story_reply_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramStoryReply.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramStoryReply.description',
        ),
        pic: instagramStoryReplyEntryPointSrc,
      },
      {
        id: PluginType.instagram_comments_autoreply_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramCommentsAutoreply.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramCommentsAutoreply.description',
        ),
        pic: instagramCommentsAutoreplyEntryPointSrc,
      },
      {
        id: PluginType.instagram_ads_manager_ctm_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramAdsCTM.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramAdsCTM.description',
        ),
        pic: instagramAdsManagerCtmEntryPointSrc,
      },
      {
        id: PluginType.instagram_persistent_menu_entry_point,
        category: Category.instagram,
        platform: Platform.instagram,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramPersistentMenu.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.InstagramPersistentMenu.description',
        ),
        pic: instagramPersistentMenuEntryPointSrc,
      },
    ];

    // @ts-expect-error
    const facebookEntryPoints: FacebookEntryPoints = [
      {
        id: PluginType.ref_link_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.BotLink.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.BotLink.description',
        ),
        pic: refLinkEntryPointSrc,
      },
      {
        id: PluginType.ads_manager_ctm_json_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookAdsCTMJson.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookAdsCTMJson.description',
        ),
        pic: adsManagerCtmJsonEntryPointSrc,
      },
      {
        id: PluginType.ads_manager_ctm_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookAdsCTM.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookAdsCTM.description',
        ),
        pic: adsManagerCtmEntryPointSrc,
      },
      {
        id: PluginType.comments_autoreply_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookPostComments.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookPostComments.description',
        ),
        pic: commentsAutoreplyEntryPointSrc,
      },
      // params.isAdCommentsAutoreplyEnabled
      //   ? {
      //       id: PluginType.ad_comments_autoreply_entry_point,
      //       category: Category.facebook,
      //       platform: Platform.facebook,
      //       title: i18n.t(
      //         'modernComponents.EntryPointsModal.entryPoints.AdCommentsAutoreply.title',
      //       ),
      //       description: i18n.t(
      //         'modernComponents.EntryPointsModal.entryPoints.AdCommentsAutoreply.description',
      //       ),
      //       pic: adCommentsAutoreplyEntryPointSrc,
      //     }
      //   : undefined,
      {
        id: PluginType.fb_page_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookPage.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookPage.description',
        ),
        pic: fbPageEntryPointSrc,
      },
      {
        id: PluginType.facebook_shops_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookShops.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.FacebookShops.description',
        ),
        pic: facebookShopsEntryPointSrc,
      },
      {
        id: PluginType.persistent_menu_entry_point,
        category: Category.facebook,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.PersistentMenu.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.PersistentMenu.description',
        ),
        pic: persistentMenuEntryPointSrc,
      },
      // {
      //   id: PluginType.ads_manager_sm_entry_point,
      //   category: Category.facebook,
      //   platform: Platform.facebook,
      //   title: i18n.t(
      //     'modernComponents.EntryPointsModal.entryPoints.FacebookAdsSM.title',
      //   ),
      //   description: i18n.t(
      //     'modernComponents.EntryPointsModal.entryPoints.FacebookAdsSM.description',
      //   ),
      //   pic: adsManagerSmEntryPointSrc,
      // },
    ].filter(Boolean);

    const websiteEntryPoints: WebsiteEntryPoints = [
      {
        id: PluginType.ref_link_entry_point,
        category: Category.website,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.BotLink.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.BotLink.description',
        ),
        pic: refLinkEntryPointSrc,
      },
      // {
      //   id: PluginType.customer_chat_entry_point,
      //   category: Category.website,
      //   platform: Platform.facebook,
      //   title: i18n.t(
      //     'modernComponents.EntryPointsModal.entryPoints.CustomerChat.title',
      //   ),
      //   description: i18n.t(
      //     'modernComponents.EntryPointsModal.entryPoints.CustomerChat.description',
      //   ),
      //   pic: customerChatEntryPointSrc,
      // },
      {
        id: PluginType.send_to_messenger_entry_point,
        category: Category.website,
        platform: Platform.facebook,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.SendToMessenger.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.SendToMessenger.description',
        ),
        pic: sendToMessengerEntryPointSrc,
      },
    ];

    const whatsappEntryPoints: WhatsappEntryPoints = [
      {
        id: BlockShortcutId.whatsappDirect,
        category: Category.whatsapp,
        platform: Platform.whatsapp,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappConversation.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappConversation.description',
        ),
        pic: whatsappDirectEntryPointSrc,
      },
      {
        id: PluginType.whatsapp_bot_link,
        category: Category.whatsapp,
        platform: Platform.whatsapp,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappBotlink.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappBotlink.description',
        ),
        pic: whatsappBotLinkEntryPointSrc,
      },
      {
        id: PluginType.whatsapp_widget_entry_point,
        category: Category.whatsapp,
        platform: Platform.whatsapp,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappWidgetEntryPoint.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappWidgetEntryPoint.description',
        ),
        pic: whatsappWidgetEntryPointSrc,
      },
      {
        id: PluginType.whatsapp_popup_entry_point,
        category: Category.whatsapp,
        platform: Platform.whatsapp,
        title: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappPopupEntryPoint.title',
        ),
        description: i18n.t(
          'modernComponents.EntryPointsModal.entryPoints.WhatsappPopupEntryPoint.description',
        ),
        pic: whatsappPopupEntryPointSrc,
      },
    ];

    return [
      ...(params.isWhatsappEnabled
        ? whatsappEntryPoints
        : [
            ...facebookEntryPoints,
            ...instagramEntryPoints,
            ...websiteEntryPoints,
          ]),
    ];
  },
);
