import { AdminFeaturesQuery_me_features } from '@utils/Data/Admin/@types/AdminFeaturesQuery';
import { FeaturePromoGalleriesKeys } from './consts';

export const filterAvailiableFeatures = (
  features: FeaturePromoGalleriesKeys[],
  adminFeatureToggles: AdminFeaturesQuery_me_features,
  seenFeatures: FeaturePromoGalleriesKeys[],
) => {
  return features.filter(
    (feature) =>
      adminFeatureToggles[feature] && !seenFeatures.includes(feature),
  );
};
