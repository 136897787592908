import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import {
  GoToMessengerDialog,
  IGoToMessengerDialogProps,
} from '../GoToMessengerDialog/GoToMessengerDialog';
import { Modal } from '../../../modern-ui/Modal';

const GoToMessengerPopup: React.SFC<IGoToMessengerDialogProps> = (props) => (
  <Modal onDismiss={props.onRequestClose}>
    <GoToMessengerDialog {...props} />
  </Modal>
);

export const ngGoToMessengerPopup = ng
  .module(
    'app.modernComponents.SendToMessengerPluginButton.goToMessengerPopup',
    [],
  )
  .component(
    'goToMessengerPopup',
    react2angular(GoToMessengerPopup, ['pageId', 'onRequestClose'], []),
  ).name;
