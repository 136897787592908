import React, { useState } from 'react';
import { propEq } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Loader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { GroupingCombobox } from '@components/GroupingCombobox/GroupingCombobox';

import { useEntryPointSelectorData } from './helpers';

import * as css from './EntryPointSelectorDialog.css';

export interface EntryPointSelectorProps {
  onSelectExisting(params: { flowId: string; entryPointId: string }): void;
  onDismiss?(): void;
  onNoEntryPoints?(): void;
  triggerSelectIfOneEP?: boolean;
  flowId?: string;
}

export const EntryPointSelectorDialog: React.FC<EntryPointSelectorProps> = ({
  flowId,
  onDismiss,
  onSelectExisting,
}) => {
  const { t } = useSafeTranslation();
  const [selectedEntryPointId, setSelectedEntryPointId] = useState<
    string | null
  >(null);

  const { flowEntryPoints, isDataLoading } = useEntryPointSelectorData(flowId);
  const isNoItems = flowEntryPoints.length === 0;

  return (
    <DefaultDialog
      header={t('modernComponents.EntryPointSelectorDialog.header')}
      onDismiss={onDismiss}
      dialogStyle={{ width: '432px' }}
    >
      <div className={css.container}>
        <Type size="15px_DEPRECATED" weight="semibold">
          {t('modernComponents.EntryPointSelectorDialog.title')}
        </Type>
        <Spacer factor={2} />
        {isDataLoading ? (
          <Loader />
        ) : (
          <GroupingCombobox
            ungroupedItems={flowId ? flowEntryPoints[0].items : undefined}
            groups={!flowId ? flowEntryPoints : []}
            emptyPlaceholder={
              isNoItems
                ? t('modernComponents.EntryPointSelectorDialog.emptyState')
                : t(
                    'modernComponents.EntryPointSelectorDialog.chooseEntrypoint',
                  )
            }
            disable={isNoItems}
            selectedItemId={selectedEntryPointId}
            onSelect={setSelectedEntryPointId}
          />
        )}
        <Spacer factor={10} />
        <Flex justifyContent="flex-end">
          <Button
            disabled={!selectedEntryPointId || isDataLoading}
            onClick={() => {
              if (selectedEntryPointId) {
                const epIdMatches = propEq('id', selectedEntryPointId);
                const flow = flowEntryPoints.find((currentFlow) =>
                  currentFlow.items.some(epIdMatches),
                );
                if (flow) {
                  const entryPoint = flow.items.find(epIdMatches);
                  if (entryPoint) {
                    onSelectExisting({
                      flowId: flow.id,
                      entryPointId: entryPoint.id,
                    });
                  }
                }
              }
            }}
          >
            {t('modernComponents.EntryPointSelectorDialog.enableEntrypoint')}
          </Button>
        </Flex>
      </div>
    </DefaultDialog>
  );
};
