import { PluginType } from '@components/Plugins/common/PluginTypes';
import React from 'react';
import { Platform } from '@globals';
import { EntryPointsModal } from './EntryPointsModal';
import { BlockShortcutId } from '@components/FlowBuilder/views/helpers/blocksShortcuts';

interface EntryPointsNewFlowModal {
  flowId: string;
  onClose(): void;
  onSelect(
    pluginType?: PluginType | BlockShortcutId,
    onCompleted?: (entryPointBlockId: string) => Promise<void>,
  ): void;
  platform?: Platform;
}

export const EntryPointsNewFlowModal: React.FC<EntryPointsNewFlowModal> = ({
  flowId,
  onClose,
  onSelect,
  platform,
}) => (
  <EntryPointsModal
    flowId={flowId}
    platform={platform ?? Platform.facebook}
    onClose={onClose}
    onSelect={onSelect}
  />
);
