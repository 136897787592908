import React from 'react';
import {
  EntryPointSelectorDialog,
  EntryPointSelectorProps,
} from './EntryPointSelectorDialog';
import { Modal } from '@services/index';

export const showEntryPointSelectorDialog = ({
  onDismiss,
  onSelectExisting,
  flowId,
}: EntryPointSelectorProps) => {
  Modal.show<{ flowId: string; entryPointId: string }>(
    ({ close, resolve }) => {
      return (
        <EntryPointSelectorDialog
          flowId={flowId}
          onDismiss={() => {
            onDismiss?.();
            close();
          }}
          onSelectExisting={(params) => {
            resolve(params);
            onSelectExisting(params);
          }}
        />
      );
    },
    {
      id: 'entryPointSelector',
      onOverlayDismiss: onDismiss,
    },
  );
};
