import React from 'react';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const FacebookPageArchivedTooltip = () => {
  const { t } = useSafeTranslation();

  return (
    <Tooltip2
      content={t(
        'modernComponents.FacebookPagesSetup.pagesList.archivedTooltip',
      )}
      hoverable
    >
      {(ref, bind) => (
        <Flex alignItems="center" ref={ref} {...bind}>
          <Type color="greyDark" size="15px">
            {t('modernComponents.FacebookPagesSetup.pagesList.archived')}
          </Type>
          <Spacer horizontalFactor={1} />
          <Icon icon="info" size="16px" color="grey" />
        </Flex>
      )}
    </Tooltip2>
  );
};
