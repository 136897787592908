import gql from 'graphql-tag';

export const ENTRY_POINTS_SELECTOR_QUERY = gql`
  query EntryPointsSelectorQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        flow_ids
      }
      flows {
        id
        title
        entry_points {
          id
          title
          enabled
          entry_point_type
        }
      }
    }
  }
`;
