import React from 'react';
import { Tooltip2 } from '@ui/Tooltip2';
import { UploadFile, UploadFileProps } from '@utils/UploadFile';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { FileAttachmentType } from '@utils/UploadService/types';
import { DropState } from '@utils/UploadFile/UploadFile';

export interface FileSelectorChildrenProps {
  chooseFile(attachmentType: FileAttachmentType): void;
  ref?: React.Ref<HTMLButtonElement> | undefined;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  dropState: DropState;
}

export interface FileSelectorProps extends Omit<UploadFileProps, 'children'> {
  children(props: FileSelectorChildrenProps): React.ReactNode;
  withTooltip?: boolean;
}

export const FileSelector: React.FC<FileSelectorProps> = ({
  children,
  withTooltip,
  ...props
}) => {
  const { t } = useSafeTranslation();

  if (withTooltip) {
    return (
      <Tooltip2
        content={t('modernComponents.FileSelector.tooltipText')}
        placement="top"
        type="small"
      >
        {(refSticker, bindSticker) => (
          <>
            <UploadFile {...props}>
              {({ chooseFile, dropState }) =>
                children({
                  chooseFile,
                  dropState,
                  ref: refSticker,
                  ...bindSticker,
                })
              }
            </UploadFile>
          </>
        )}
      </Tooltip2>
    );
  }
  return (
    <UploadFile {...props}>
      {({ chooseFile, dropState }) => children({ chooseFile, dropState })}
    </UploadFile>
  );
};
