import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  DeepLinksMode,
  DeepLinksQueryParam,
} from '../../../pages/DeepLinks/types';

interface InstagramInstagramTemplateButtonProps {
  onDismiss(): void;
}

export const InstagramInstagramTemplateButton: React.FC<InstagramInstagramTemplateButtonProps> = ({
  onDismiss,
}) => {
  const history = useHistory();
  const botId = useCurrentBotId();

  return (
    <Button
      intent="primary"
      onClick={() => {
        sendEvent({
          category: 'feature promo modal',
          action: 'click install template button',
        });
        onDismiss();
        history.push(
          `${getTabLink(BotTabs.flows, botId)}?${DeepLinksQueryParam.mode}=${
            DeepLinksMode.templatesModal
          }`,
        );
      }}
    >
      {window.i18next.t(
        'InstagramInstagramTemplateButton-JSXText--507-install-template',
      )}
    </Button>
  );
};
