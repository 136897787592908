import React from 'react';
import { compose } from 'ramda';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '../../../modern-ui/Dialog';
import { ReactComponent as FbMessengerIcon } from '../../../modern-ui/_deprecated/Icon/icons/fmessenger.svg';
import { openMessengerPage } from '../FBPluginsUtils';
import * as css from './GoToMessengerDialog.css';

export interface IGoToMessengerDialogProps {
  pageId: string;
  onRequestClose: () => void;
}

export const GoToMessengerDialog: React.SFC<IGoToMessengerDialogProps> = ({
  pageId,
  onRequestClose,
}) => {
  return (
    <Dialog className={css.dialog}>
      <DialogContent>
        <DialogHeading>
          {window.i18next.t(
            'GoToMessengerDialog-JSXText--626-now-open-your-messenger',
          )}
        </DialogHeading>
        <div>
          {window.i18next.t(
            'GoToMessengerDialog-JSXText--243-all-set-now-open-the-messenger-to-see-your-chatbot',
          )}
        </div>
        <Button
          className={css.button}
          intent={ButtonIntent.primary}
          onClick={compose(onRequestClose, () => openMessengerPage({ pageId }))}
          renderIcon={() => <FbMessengerIcon />}
        >
          {window.i18next.t('GoToMessengerDialog-JSXText-1291-open-messenger')}
        </Button>
      </DialogContent>
      <DialogCloseButton className={css.closeButton} onClick={onRequestClose} />
    </Dialog>
  );
};
