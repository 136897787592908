import React from 'react';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Avatar, AvatarSize } from '@ui/Avatar';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TextEllipsis } from '@ui/TextEllipsis';

interface FacebookPagePublishedByProps {
  name: string | null;
  picture: string | null;
}

export const FacebookPagePublishedBy: React.FC<FacebookPagePublishedByProps> =
  ({ name, picture }) => {
    const { t } = useSafeTranslation();

    return (
      <Flex alignItems="center">
        <TextEllipsis>
          <Type size="12px">
            {t(
              'FacebookPagesSetupStateConnectedPage-JSXText-2404-published-by',
              {
                name,
              },
            )}
          </Type>
        </TextEllipsis>
        {picture && (
          <>
            <Spacer factor={0} horizontalFactor={1} />

            <Avatar src={picture} size={AvatarSize.small} />
          </>
        )}
      </Flex>
    );
  };
