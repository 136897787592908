import React from 'react';
import memoizeOne from 'memoize-one';
import cn from 'classnames';
import { Modal } from '@ui/Modal';
import { callFacebookSDK } from '../callFacebookSDK';
import { GoToMessengerDialog } from '../GoToMessengerDialog';
import { parseFbPluginsTemplates, isFbSdkReadyAsync } from '../FBPluginsUtils';
import * as css from './SendToMessengerPluginButton.css';

export const FB_SEND_TO_PLUGIN_NAME = 'send_to_messenger';

export type FBPluginEvent = {
  event: string;
  ref: string | undefined;
};

interface ISendToMessengerPluginButtonProps
  extends React.HTMLProps<HTMLDivElement> {
  render: (state: ISendToMessengerPluginButtonState) => React.ReactNode;
  dataRef: string | undefined;
  pageId: string;
  appId: string;
  useGoToMessengerDialog?: boolean;
  onClickFB?: () => void;
  onOptIn?: () => void;
  onRendered?: () => void;
  onError?: () => void;
  preventAutoReInit?: boolean;
}

export interface ISendToMessengerPluginButtonState {
  sendToMessengerPluginRendered: boolean;
  fbApiReady: boolean;
  fbContainerKey: number;
  showGoToMessengerDialog: boolean;
  ready: boolean;
  watchOptIn: boolean;
  renderError: boolean;
}

const RENDER_TIMEOUT = 8000;

export class SendToMessengerPluginButton extends React.Component<
  ISendToMessengerPluginButtonProps,
  ISendToMessengerPluginButtonState
> {
  private nodeRef = React.createRef<HTMLDivElement>();

  state = {
    sendToMessengerPluginRendered: false,
    fbApiReady: false,
    fbContainerKey: Math.random(),
    showGoToMessengerDialog: false,
    ready: false,
    watchOptIn: false, // fb after login send opt_in event for all!! wtf??!!
    renderError: false,
  };

  needFbPluginParse: boolean = false;

  updateTimeout: number | undefined;

  renderErrorTimeout: number | undefined;

  renderSendToMessengerFbPlugin = memoizeOne(
    (
      pageId: string,
      appId: string,
      fbContainerKey: number,
      fbApiReady: boolean,
      dataRef?: string,
    ) => {
      this.needFbPluginParse = true;
      if (this.renderErrorTimeout) {
        window.clearTimeout(this.renderErrorTimeout);
      }

      this.renderErrorTimeout = window.setTimeout(() => {
        this.setState({
          renderError: true,
        });
        this.props.onError?.();
      }, RENDER_TIMEOUT);

      return fbApiReady ? (
        <div
          key={`fbContainer_${fbContainerKey}`}
          className={css.fbPlugin}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
                <div
                  page_id="${pageId}"
                  messenger_app_id="${appId}"
                  ${dataRef ? `data-ref="${dataRef}"` : ''}
                  class="fb-send-to-messenger"
                  color="blue"
                  size="xlarge"
                ></div>
              `,
          }}
        />
      ) : (
        undefined
      );
    },
  );

  componentDidMount() {
    this.setFBHandler();
  }

  componentDidUpdate() {
    const { fbApiReady } = this.state;
    if (this.needFbPluginParse && fbApiReady) {
      this.needFbPluginParse = false;
      if (this.nodeRef.current) {
        parseFbPluginsTemplates(this.nodeRef.current);
      }
    }
  }

  componentWillUnmount() {
    callFacebookSDK((FB) => {
      FB.Event.unsubscribe(FB_SEND_TO_PLUGIN_NAME, this.handleFbEvents);
    });

    if (this.updateTimeout) {
      window.clearTimeout(this.updateTimeout);
    }
    if (this.renderErrorTimeout) {
      window.clearTimeout(this.renderErrorTimeout);
    }
  }

  handleFbEvents = (eventData: FBPluginEvent) => {
    const { ref, event } = eventData;
    const {
      dataRef,
      useGoToMessengerDialog,
      onClickFB,
      onOptIn,
      preventAutoReInit,
      onRendered,
    } = this.props;
    const { sendToMessengerPluginRendered, watchOptIn } = this.state;
    if (ref === dataRef) {
      switch (event) {
        case 'clicked':
          // fix cross-domain iframe access error in Safari 12 ...
          // React touch the active element and this threw an exception!
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          window.focus();
          if (sendToMessengerPluginRendered) {
            if (useGoToMessengerDialog) {
              this.showGoToMessengerDialog();
            }

            if (onClickFB) {
              onClickFB();
            }

            this.setState({
              ready: false,
              watchOptIn: true,
            });
          }
          break;
        case 'opt_in':
          if (sendToMessengerPluginRendered && watchOptIn) {
            if (!preventAutoReInit) {
              this.updateTimeout = window.setTimeout(() => {
                this.setState({
                  fbContainerKey: Math.random(),
                  sendToMessengerPluginRendered: false,
                });

                if (this.nodeRef.current) {
                  parseFbPluginsTemplates(this.nodeRef.current);
                }
              }, 3000); // Wait for plugin send data to FB
            }

            if (onOptIn) {
              onOptIn();
            }

            this.setState({
              watchOptIn: false,
            });
          }
          break;
        case 'rendered':
          this.setState({
            sendToMessengerPluginRendered: true,
            ready: true,
          });
          if (this.renderErrorTimeout) {
            window.clearTimeout(this.renderErrorTimeout);
          }
          if (onRendered) {
            onRendered();
          }
          break;
        default:
      }
    }
  };

  setFBHandler = async () => {
    await isFbSdkReadyAsync;
    callFacebookSDK((FB) => {
      FB.Event.subscribe(FB_SEND_TO_PLUGIN_NAME, this.handleFbEvents);
    });
    this.setState({
      fbApiReady: true,
    });
  };

  closeGoToMessengerDialog = () => {
    this.setState({
      showGoToMessengerDialog: false,
    });
  };

  showGoToMessengerDialog = () => {
    this.setState({
      showGoToMessengerDialog: true,
    });
  };

  render() {
    const {
      render,
      dataRef,
      pageId,
      appId,
      useGoToMessengerDialog,
      preventAutoReInit,
      onClickFB,
      onOptIn,
      onRendered,
      ...htmlProps
    } = this.props;
    const {
      showGoToMessengerDialog,
      ready,
      fbContainerKey,
      fbApiReady,
      sendToMessengerPluginRendered,
    } = this.state;
    return (
      <div {...htmlProps} className={css.container} ref={this.nodeRef}>
        <div
          className={cn(css.fbPluginBox, { [css.eventTransparent]: !ready })}
        >
          {dataRef &&
            this.renderSendToMessengerFbPlugin(
              pageId,
              appId,
              fbContainerKey,
              fbApiReady,
              dataRef,
            )}
        </div>
        <div
          className={cn(css.visibleElement, {
            'test-fb-plugin-rendered': sendToMessengerPluginRendered,
          })}
        >
          {render(this.state)}
        </div>
        {showGoToMessengerDialog && (
          <Modal onDismiss={this.closeGoToMessengerDialog}>
            <GoToMessengerDialog
              onRequestClose={this.closeGoToMessengerDialog}
              pageId={pageId}
            />
          </Modal>
        )}
      </div>
    );
  }
}
