import React from 'react';
import { Emoji } from '@ui/Emoji';
import { FeaturePromoItem } from '../types';
import img1 from './images/1.png';
import img2 from './images/2.png';
import img3 from './images/3.png';
import img4 from './images/4.png';
import { InstagramInstagramTemplateButton } from './InstagramInstagramTemplateButton';

const dialogTitle = (
  <>
    Instagram automation now available for all accounts{' '}
    <Emoji emoji="🎉" size={15} />
  </>
);

export const ITEMS: FeaturePromoItem[] = [
  {
    imageSrc: img1,
    dialogTitle,
    text:
      'Automatically respond to Story mentions, Story replies, comments on your Instagram posts, and DMs with repetitive questions. ',
    footer: ({ close }) => (
      <InstagramInstagramTemplateButton onDismiss={close} />
    ),
  },
  {
    imageSrc: img2,
    dialogTitle,
    text: 'Automate your promotions and coupon giveaways.',
    footer: ({ close }) => (
      <InstagramInstagramTemplateButton onDismiss={close} />
    ),
  },
  {
    imageSrc: img3,
    dialogTitle,
    text: (
      <>
        Send out links to all the interested users (the “click the link in bio”
        nightmare is finally over <Emoji emoji="🙌" size={15} />
        ).
      </>
    ),
    footer: ({ close }) => (
      <InstagramInstagramTemplateButton onDismiss={close} />
    ),
  },
  {
    imageSrc: img4,
    dialogTitle,
    text: 'Сonnect chatbot flows to paid ads to attract new customers.',
    footer: ({ close }) => (
      <InstagramInstagramTemplateButton onDismiss={close} />
    ),
  },
];
