import React from 'react';
import { Button } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { FeaturePromoItem } from '../types';
import img1 from './images/1.png';
import img2 from './images/2.png';

export const ITEMS: FeaturePromoItem[] = [
  {
    imageSrc: img1,
    dialogTitle: <>Testing has never been this easy!</>,
    text: (
      <>
        You can finally test your Instagram flows!{' '}
        <Emoji emoji="🎉" size="1em" />. To do that—use our very own chat
        widget. It's in beta for now, so if you have any comments—send them our
        way.
      </>
    ),
    footer: ({ close }) => (
      <Button
        onClick={() => {
          close();
        }}
      >
        Done
      </Button>
    ),
  },
  {
    imageSrc: img2,
    dialogTitle: <>Testing has never been this easy!</>,
    text: (
      <>
        And what about Facebook flows? You'll be able to test those using our
        chat widget soon too. But you can always test them with the usual
        Facebook widget if you want.
      </>
    ),
    footer: ({ close }) => (
      <Button
        onClick={() => {
          close();
        }}
      >
        Done
      </Button>
    ),
  },
];
