import { callFacebookSDK } from './callFacebookSDK';
import { initFbSdkEnvAppId } from './initFbSdkEnvAppId';

export enum FbLoginStatus {
  connected = 'connected',
  not_authorized = 'not_authorized',
  unknown = 'unknown',
}

export interface GetFbLoginStatusResponse {
  status: FbLoginStatus;
  authResponse?: {
    accessToken: string;
    expiresIn: string;
    signedRequest: string;
    userID: string;
  };
}

export const getFbLoginStatus = async (
  force?: boolean,
): Promise<GetFbLoginStatusResponse> => {
  return new Promise<GetFbLoginStatusResponse>((resolve) => {
    const resolveUnknownStatus = () =>
      resolve({ status: FbLoginStatus.unknown });
    // if user dosn't have auth in FB or fb sdk can not be inited
    const breakTimeout = window.setTimeout(resolveUnknownStatus, 10000);
    initFbSdkEnvAppId()
      .then(() => {
        callFacebookSDK((FB) => {
          FB.getLoginStatus((response: GetFbLoginStatusResponse) => {
            window.clearTimeout(breakTimeout);
            return resolve(response);
          }, force || false);
        });
      })
      .catch(() => {
        resolveUnknownStatus();
        window.clearTimeout(breakTimeout);
      });
  });
};
