import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { T } from 'ramda';

import { ENTRY_POINT_SUBTYPE, useAddFlowBlock } from '@utils/Data/Flow';
import { BotTabs, useCurrentBotId, getTabLink } from '@utils/Routing';

import { Modal } from '@services/.';

import { FlowPositionInput, Platform } from '@globals';
import {
  EntryPointEventTrigger,
  entryPointsEmitter,
  EntryPointsEvents,
} from '../FlowBuilder/views/entry-points/events';
import { getDefaultPlugins } from '../FlowBuilder/views/utils';
import { EntryPointsNewFlowModal } from './EntryPointsNewFlowModal';

interface UseEntryPointModalParams {
  onDataUpdated?: () => void;
}
export const useEntryPointsModal = ({
  onDataUpdated,
}: UseEntryPointModalParams) => {
  const history = useHistory();
  const botId = useCurrentBotId() ?? '';
  const { addFlowBlock } = useAddFlowBlock();

  const showEntryPointModal = useCallback(
    (flowId: string, platform?: Platform, position?: FlowPositionInput) => {
      Modal.show(
        ({ close }) => (
          <EntryPointsNewFlowModal
            flowId={flowId}
            platform={platform}
            onClose={close}
            onSelect={(pluginType, onCompleted) => {
              if (pluginType) {
                addFlowBlock({
                  flowId,
                  position,
                  subtype: ENTRY_POINT_SUBTYPE,
                  pluginIds: [pluginType, ...getDefaultPlugins(pluginType)],
                  async onCompleted(addFlowBlock) {
                    if (addFlowBlock?.id) {
                      entryPointsEmitter.emit(EntryPointEventTrigger.flowList, {
                        data: {
                          event: EntryPointsEvents.create,
                          flowId,
                          id: addFlowBlock.id,
                        },
                      });

                      await onCompleted?.(addFlowBlock.id);
                      history.push(
                        getTabLink(BotTabs.flows, botId, {
                          flowId,
                          blockId: addFlowBlock.id,
                        }),
                      );
                    }
                    close();
                  },
                });
              } else {
                close();
              }
              onDataUpdated?.();
            }}
          />
        ),
        { skipOverloadActive: T },
      );
    },
    [addFlowBlock, botId, history, onDataUpdated],
  );

  return { showEntryPointModal };
};
