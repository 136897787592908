import React from 'react';
import i18next from 'i18next';
import { Button } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { FeaturePromoItem } from '../types';
import img1 from './images/inbound-links.png';

export const ITEMS: FeaturePromoItem[] = [
  {
    imageSrc: img1,
    dialogTitle: (
      <>
        {i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.InboundLinks.promo.title',
        )}{' '}
        <Emoji emoji="✨" size="1em" />
      </>
    ),
    text: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.InboundLinks.promo.description',
    ),
    footer: ({ close }) => (
      <Button
        onClick={() => {
          close();
        }}
      >
        {i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.InboundLinks.promo.button',
        )}
      </Button>
    ),
  },
];
